define("m12-2020/router", ["exports", "m12-2020/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('projektdaten');
    this.route('traeger');
    this.route('auflager');
    this.route('gabellager');
    this.route('lasteinwirkung');
    this.route('optionen');
    this.route('verbindungsmittel');
    this.route('ergebnis');
    this.route('impressum');
    this.route('contact');
    this.route('downloadcenter');
  });
});