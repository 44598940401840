define("m12-2020/controllers/downloadcenter", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    savingInProgress: false,
    saved: false,
    saveFile: "",
    pdfErstellt: false,
    dlPaketFilename: "testpaket2016",
    dlPaketLink: "http://www.google.de",
    packageIsBeingCreated: false,
    packageCreated: false,
    inputCheckbox: false,
    pdfCheckbox: false,
    pinfoCheckbox: false,
    pkatalogCheckbox: false,
    pcadCheckbox: false,
    etaCheckbox: false,
    schraubenInfo: "",
    schraubenCAD: "",
    produktkatalog: "http://www.onlinebemessung.de/webapps/webapp_wuerth/produktinfo/de/Catalog.pdf",
    eta: "http://www.onlinebemessung.de/webapps/webapp_wuerth/produktinfo/de/ETA-110190.pdf",
    validScrewSelected: false,
    cid: "",
    etaNummer: "",
    etaFehlt: true,
    katalogFehlt: true,
    berechnungDisabled: true,
    dxfDisabled: true,
    pdfFile: "",
    intl: Ember.inject.service(),
    ttInNichtGespeichert: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('eingabedatennichtgespeichert');
      },

      set(key, value) {
        return value;
      }

    }),
    ttPdfNochNichtErstellt: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('pdfnochnichterstellt');
      },

      set(key, value) {
        return value;
      }

    }),
    ttProduktkatalogFehlt: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('produktkatalogFehlt');
      },

      set(key, value) {
        return value;
      }

    }),
    ttKeineSchraubeGewaehlt: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('nochkeineschraubegewaehlt');
      },

      set(key, value) {
        return value;
      }

    }),
    ttKeinDLErzeugt: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('nochkeindlerzeugt');
      },

      set(key, value) {
        return value;
      }

    }),
    actions: {
      createDownloadPackage: function () {
        var self = this;
        var application = self.controllerFor('application');
        var applicationdata = application.get('model').application.objectAt(0);
        var cid = applicationdata.get('Calculation_ID');
        var filename = "";

        if (self.get('dlfilename') === "" || self.get('dlfilename') === undefined) {
          filename = cid;
        } else {
          filename = self.get('dlfilename');
        }

        var inputCBvalue = self.get('inputCheckbox');
        var pdfCBvalue = self.get('pdfCheckbox');
        var pinfoCBvalue = self.get('pinfoCheckbox');
        var pkatalogCBvalue = self.get('pkatalogCheckbox');
        var pcadCBvalue = self.get('pcadCheckbox');
        var etaCBvalue = self.get('etaCheckbox');
        self.set('packageIsBeingCreated', true);

        _jquery.default.ajax({
          type: "POST",
          url: "http://136.243.4.143/berechnungsservice/dlPaketErstellen/",
          data: {
            cid: cid,
            filename: filename,
            input: inputCBvalue,
            pdf: pdfCBvalue,
            pinfo: pinfoCBvalue,
            pkatalog: pkatalogCBvalue,
            pcad: pcadCBvalue,
            eta: etaCBvalue
          }
        }).done(function (data) {
          self.set('dlPaketFilename', filename);
          self.set('dlPaketLink', data);
          self.set('packageIsBeingCreated', false);
          self.set('packageCreated', true);
        });
      }
    }
  });

  _exports.default = _default;
});