define("m12-2020/controllers/optionen", ["exports", "m12-2020/mixins/object-validator"], function (_exports, _objectValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_objectValidator.default, (_obj = {
    intl: Ember.inject.service(),
    _min_n0: "1",
    _min_n90: "1",
    _min_a1cPlatte: "0.0",
    _min_a2cPlatte: "0.0",
    _sicherheitsAbstand: "0.0",
    openFromFile: false,
    ttmin_n0: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 1 - 999";
      },

      set(key, value) {
        return value;
      }

    }),
    ttmin_n90: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 1 - 999";
      },

      set(key, value) {
        return value;
      }

    }),
    ttmin_a1cPlatte: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 1 - 999.0 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    ttmin_a2cPlatte: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 1 - 999.0 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    ttsicherheitsAbstand: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 1 - 999.0 [cm]";
      },

      set(key, value) {
        return value;
      }

    }),
    validations: {
      _min_n0: {
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 999,
          message: "*"
        }
      },
      _min_n90: {
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 999,
          message: "*"
        }
      },
      _min_a1cPlatte: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0,
          message: "*"
        }
      },
      _min_a2cPlatte: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0,
          message: "*"
        }
      },
      _sicherheitsAbstand: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0,
          message: "*"
        }
      }
    },
    applicationController: Ember.inject.controller('application'),
    // auflagerController: inject('auflager'),
    // optionenController: inject('optionen'),
    // lasteinwirkungController: inject('lasteinwirkung'),
    // superController: inject('supercontroller'),
    downloadcenterController: Ember.inject.controller('downloadcenter'),
    init: function () {
      this._super();

      console.log("inti optionen");
    },
    initialisierung: function () {},
    setValues: function (values) {
      var self = this;

      if (self.debug) {
        console.log("values in bauteile: ");
        console.log(values);
      }

      this.set('openFromFile', true);
      this.set('_min_n0', values.min_n0);
      this.setX3D(parseInt(values.min_n0), {
        target: {
          name: "_min_n0"
        }
      });
      this.set('_min_n90', values.min_n90);
      this.setX3D(parseInt(values.min_n90), {
        target: {
          name: "_min_n90"
        }
      });
      this.set('_min_a1cPlatte', values.min_a1cPlatte);
      this.setX3D(Number(values.min_a1cPlatte), {
        target: {
          name: "_min_a1cPlatte"
        }
      });
      this.set('_min_a2cPlatte', values.min_a2cPlatte);
      this.setX3D(Number(values.min_a2cPlatte), {
        target: {
          name: "_min_a2cPlatte"
        }
      });
      this.set('_sicherheitsAbstand', values.sicherheitsAbstand);
      this.setX3D(Number(values.sicherheitsAbstand), {
        target: {
          name: "_sicherheitsAbstand"
        }
      });
      this.send('validation');
      this.set('openFromFile', false);
    },
    watchNumericalInputs: Ember.observer('_min_a1cPlatte', '_min_a2cPlatte', '_sicherheitsAbstand', function () {
      var self = this;

      if (!self.openFromFile) {
        if (self.get('_min_a1cPlatte').toString().indexOf(",") !== -1) {
          self.set('_min_a1cPlatte', self.get('_min_a1cPlatte').toString().replace(",", "."));
        }

        if (self.get('_min_a2cPlatte').toString().indexOf(",") !== -1) {
          self.set('_min_a2cPlatte', self.get('_min_a2cPlatte').toString().replace(",", "."));
        }

        if (self.get('_sicherheitsAbstand').toString().indexOf(",") !== -1) {
          self.set('_sicherheitsAbstand', self.get('_sicherheitsAbstand').toString().replace(",", "."));
        }
      }
    }),
    setX3D: function (value, event) {
      var self = this;
      var x3d = self.get('applicationController').get('model').x3ddefault.objectAt(0);
      var optionenData = self.get('applicationController').get('model').optionen.objectAt(0);
      x3d.set('ergebnisGeladen', false);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case '_min_n0':
          if (self.get('errors').get("_min_n0") === undefined) {
            x3d.set('min_n0', value);
            optionenData.set('min_n0', value);
            x3d.set('_min_n0Eingetragen', true);
          } else {
            x3d.set('_min_n0Eingetragen', false);
          }

          break;

        case '_min_n90':
          if (self.get('errors').get("_min_n90") === undefined) {
            x3d.set('min_n90', value);
            optionenData.set('min_n90', value);
            x3d.set('min_n90Eingetragen', true);
          } else {
            x3d.set('min_n90Eingetragen', false);
          }

          break;

        case '_min_a1cPlatte':
          if (self.get('errors').get("_min_a1cPlatte") === undefined) {
            x3d.set('min_a1cPlatte', value);
            optionenData.set('min_a1cPlatte', value);
            x3d.set('min_a1cPlatteEingetragen', true);
          } else {
            x3d.set('min_a1cPlatteEingetragen', false);
          }

          break;

        case '_min_a2cPlatte':
          if (self.get('errors').get("_min_a2cPlatte") === undefined) {
            x3d.set('min_a2cPlatte', value);
            optionenData.set('min_a2cPlatte', value);
            x3d.set('min_a2cPlatteEingetragen', true);
          } else {
            x3d.set('min_a2cPlatteEingetragen', false);
          }

          break;

        case '_sicherheitsAbstand':
          if (self.get('errors').get("_sicherheitsAbstand") === undefined) {
            x3d.set('sicherheitsAbstand', value);
            optionenData.set('sicherheitsAbstand', value);
            x3d.set('sicherheitsAbstandEingetragen', true);
          } else {
            x3d.set('sicherheitsAbstandEingetragen', false);
          }

          this.setSchraubenlaenge();
          break;
      }

      x3d.set('transformHelper', !x3d.get('transformHelper'));
      this.get('applicationController').zentriertObjekt();
    },

    validation(value, event) {
      var self = this;

      if (!self.openFromFile) {
        this.setX3D(value, event);
      }

      if (self.validate() === true) {
        self.set('displayErrors', false);
        self.get('applicationController').set('optionenInvalid', false);
        var applicationdata = self.get('applicationController').get('model').application.objectAt(0);
        applicationdata.set('treeLoaded', false);
        applicationdata.set('initialized', false);
        applicationdata.set('pdfErstellt', false);
        self.get('downloadcenterController').set('pdfErstellt', false);
        self.get('applicationController').set('verbindungsmittelInvalid', true);
      } else {
        self.set('displayErrors', true);
        self.get('applicationController').set('optionenInvalid', true);
      }
    },

    _min_n0IsSelected() {
      var min_n0Aktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('min_n0Aktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('min_n0Aktiv', !min_n0Aktiv);

      if (min_n0Aktiv === false) {
        document.getElementsByName('_min_n0')[0].setSelectionRange(0, this.get('_min_n0').length);
      }
    },

    _min_n90IsSelected() {
      var min_n90Aktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('min_n90Aktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('min_n90Aktiv', !min_n90Aktiv);

      if (min_n90Aktiv === false) {
        document.getElementsByName('_min_n90')[0].setSelectionRange(0, this.get('_min_n90').length);
      }
    },

    _min_a1cPlatteIsSelected() {
      var min_a1cPlatteAktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('min_a1cPlatteAktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('min_a1cPlatteAktiv', !min_a1cPlatteAktiv);

      if (this.get('_min_a1cPlatte') !== "") {
        var work = parseFloat(this.get('_min_a1cPlatte').toString().replace(",", "."));
        var rounded = work.toFixed(1);
        this.set('_min_a1cPlatte', rounded);
        this.send('validation', rounded, {
          target: {
            name: "_min_a1cPlatte"
          }
        });
      }

      if (min_a1cPlatteAktiv === false) {
        document.getElementsByName('_min_a1cPlatte')[0].setSelectionRange(0, this.get('_min_a1cPlatte').length);
      }
    },

    _min_a2cPlatteIsSelected() {
      var min_a2cPlatteAktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('min_a2cPlatteAktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('min_a2cPlatteAktiv', !min_a2cPlatteAktiv);

      if (this.get('_min_a2cPlatte') !== "") {
        var work = parseFloat(this.get('_min_a2cPlatte').toString().replace(",", "."));
        var rounded = work.toFixed(1);
        this.set('_min_a2cPlatte', rounded);
        this.send('validation', rounded, {
          target: {
            name: "_min_a2cPlatte"
          }
        });
      }

      if (min_a2cPlatteAktiv === false) {
        document.getElementsByName('_min_a2cPlatte')[0].setSelectionRange(0, this.get('_min_a2cPlatte').length);
      }
    },

    _sicherheitsAbstandIsSelected() {
      var sicherheitsAbstandAktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('sicherheitsAbstandAktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('sicherheitsAbstandAktiv', !sicherheitsAbstandAktiv);

      if (this.get('_sicherheitsAbstand') !== "") {
        var work = parseFloat(this.get('_sicherheitsAbstand').toString().replace(",", "."));
        var rounded = work.toFixed(1);
        this.set('_sicherheitsAbstand', rounded);
        this.send('validation', rounded, {
          target: {
            name: "_sicherheitsAbstand"
          }
        });
      }

      if (sicherheitsAbstandAktiv === false) {
        document.getElementsByName('_sicherheitsAbstand')[0].setSelectionRange(0, this.get('_sicherheitsAbstand').length);
      }
    },

    setGrenzwert: function (feldname, grenzwertMax) {
      var self = this;
      self.set('validations._' + feldname + '.numericality.lessThanOrEqualTo', Number(grenzwertMax));
      self.set('tt' + feldname, self.get('intl').t('wertebereich') + " 0 - " + grenzwertMax + " [cm]");
    },
    setSchraubenlaenge: function () {
      var self = this;
      var x3d = self.get('applicationController').get('model').x3ddefault.objectAt(0);
      var bauteile = self.get('applicationController').get('model').x3ddefault.objectAt(0).get('bauteile');
      var propertiesSchraubeStandard1 = bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0);
      var length = Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y')) - Number(x3d.get('sicherheitsAbstand'));

      if (x3d.get('gabellager') === true) {
        var traegerbreite = bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z');
        var zinkenbreite = 5;
        length = Number(zinkenbreite) / 2 + Number(traegerbreite) + Number(zinkenbreite);
      }

      propertiesSchraubeStandard1.set('length', length);
      propertiesSchraubeStandard1.set('threadlengthtip', length);
    }
  }, (_applyDecoratedDescriptor(_obj, "validation", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "validation"), _obj), _applyDecoratedDescriptor(_obj, "_min_n0IsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "_min_n0IsSelected"), _obj), _applyDecoratedDescriptor(_obj, "_min_n90IsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "_min_n90IsSelected"), _obj), _applyDecoratedDescriptor(_obj, "_min_a1cPlatteIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "_min_a1cPlatteIsSelected"), _obj), _applyDecoratedDescriptor(_obj, "_min_a2cPlatteIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "_min_a2cPlatteIsSelected"), _obj), _applyDecoratedDescriptor(_obj, "_sicherheitsAbstandIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "_sicherheitsAbstandIsSelected"), _obj)), _obj));

  _exports.default = _default;
});