define("m12-2020/controllers/x3drenderer", ["exports", "m12-2020/mixins/object-validator"], function (_exports, _objectValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_objectValidator.default, {
    ueberstand: 2,
    yDeltaOben: 0,
    yDeltaUnten: 0,
    applicationController: Ember.inject.controller('application'),
    indexedFaceset: function (bauteil, istGitterModell) {
      // console.log("###  x3dcontroller  ###");
      // console.log(istGitterModell);
      return "<transform scaleorientation=\"0,0,0,0\" scale=\"1,1,1\" center=\"0 0 0\" bboxsize=\"-1,-1,-1\" bboxcenter=\"0 0 0\" rotation=\"0 0 0 0 \" translation=\"0 0 0\" render=\"true\"><shape ispickable=\"0\" bboxsize=\"-1,-1,-1\" bboxcenter=\"0,0,0\" render=\"true\"><appearance sorttype=\"auto\" alphaclipthreshold=\"0.1\"><material specularcolor=\"0.52 0.385 0.02\" shininess=\"0.2\" emissivecolor=\"" + this.farbe(bauteil) + "\" ambientintensity=\"0.2\" transparency=\"" + (istGitterModell === true ? 1 : 0.65).toString() + "\" diffusecolor=\"" + this.farbe(bauteil) + "\" ></material></appearance><indexedfaceset ccw=\"true\" colorpervertex=\"false\" colorindex=\"" + this.colorindex(bauteil) + "\" coordindex=\"" + this.coordIndex(bauteil) + "\" solid=\"true\" usegeocache=\"true\" lit=\"false\" normalpervertex=\"true\" normalupdatemode=\"fast\" convex=\"true\" normalindex=\"0\" texcoordindex=\"0\"><coordinate point=\"" + this.koordinaten(bauteil) + " \"></coordinate><color color=\"" + this.farbe(bauteil) + "\"></color></indexedfaceset></shape><shape><indexedlineSet coordIndex=\"" + this.coordIndex(bauteil) + "\"><coordinate point=\"" + this.koordinaten(bauteil) + "\"/></indexedlineSet></shape></transform>";
    },
    koordinaten: function (item) {
      var p0 = '0 0 0',
          p1 = '0 0 0',
          p2 = '0 0 0',
          p3 = '0 0 0',
          p4 = '0 0 0',
          p5 = '0 0 0',
          p6 = '0 0 0',
          p7 = '0 0 0',
          p8 = '0 0 0',
          p9 = '0 0 0';
      var cords = "";
      var x0 = 0;
      var y0 = 0;
      var z0 = 0;
      var rx = 0;
      var ry = 0;
      var rz = 0;
      var dicke = 0;

      for (var n = 0; n <= 1; n++) {
        for (var i = 0; i < item.get('punkt').length; i++) {
          // console.log(i+" ::: "+(i+2)+" ::: "+(i+1));
          var i2 = i + 2 > item.get('punkt').length - 1 ? i + 2 - item.get('punkt').length : i + 2;
          var i1 = i + 1 > item.get('punkt').length - 1 ? i + 1 - item.get('punkt').length : i + 1; // console.log(i+" ::: "+(i2)+" ::: "+(i1));
          // console.log(this.getPosition(item.get('punkt').objectAt(i).get('x'), item.get('punkt').objectAt(i).get('y'), item.get('punkt').objectAt(i2).get('x'), item.get('punkt').objectAt(i2).get('y'), item.get('punkt').objectAt(i1).get('x'), item.get('punkt').objectAt(i1).get('y')));

          x0 = Number(item.get('punkt').objectAt(i).get('x')) / 10;
          y0 = Number(item.get('punkt').objectAt(i).get('y')) / 10;
          z0 = Number(item.get('punkt').objectAt(i).get('z')) / 10;
          cords = cords + (x0 + rx * dicke) + ' ' + (y0 + ry * dicke) + ' ' + (z0 + rz * dicke) + ' ';
        }

        rx = Number(item.get('richtungsvektor').objectAt(0).get('x'));
        ry = Number(item.get('richtungsvektor').objectAt(0).get('y'));
        rz = Number(item.get('richtungsvektor').objectAt(0).get('z'));
        dicke = Number(item.get('platte').objectAt(0).get('thickness')) / 10;
      } // console.log(cords);


      return cords;
    },
    coordIndex: function (item) {
      // var values = "0 3 2 1 0 -1 1 2 6 5 1 -1 4 5 6 7 4 -1 0 4 7 3 0 -1 0 1 5 4 0 -1 2 3 7 6 2 -1";
      var values = ""; // Grundfläche

      for (var i = 0; i < item.get('punkt').length; i++) {
        values = values + " " + i;
      }

      values = values + " 0 -1 "; // n-1 Seitenflächen

      for (var i = 0; i < item.get('punkt').length - 1; i++) {
        values = values + i + " " + (i + item.get('punkt').length) + " " + (i + item.get('punkt').length + 1) + " " + (i + 1) + " " + i + " " + -1 + " ";
      } // n.-Seitenfläche


      values = values + 0 + " " + (item.get('punkt').length - 1) + " " + (item.get('punkt').length - 1 + item.get('punkt').length) + " " + item.get('punkt').length + " " + 0 + " " + -1 + " ";
      var arr = []; // Abschlussfläche

      for (var i = 0; i < item.get('punkt').length; i++) {
        arr.push(i + item.get('punkt').length);
      }

      var str = arr.reverse().toString();
      values = values + " " + item.get('punkt').length + " " + str.replace(/,/g, " ") + " -1 "; // console.log(values);

      return values;
    },
    colorindex: function (item) {
      var values = "";

      for (var i = 0; i < item.get('punkt').length + 2; i++) {
        values = values + " 0";
      } // console.log(values);


      return values;
    },
    farbe: function (item) {
      // var item = x3d.get('bauteile').findBy('id', name);
      var values;
      var rot = 0,
          gruen = 0,
          blau = 0;
      var materialtextur = Number(item.get('platte').objectAt(0).get('matid')); // console.log('materialtextur: '+materialtextur);

      switch (materialtextur) {
        case 1:
          //Beton
          // console.log('Beton');
          rot = 0;
          gruen = 0.52;
          blau = 0.88;
          break;

        case 10:
          //Holz
          // console.log('Holz');
          rot = 0.52;
          gruen = 0.385;
          blau = 0.02;
          break;

        case 20:
          //Schalung
          // console.log('Schalung');
          rot = 0.8;
          gruen = 0.65;
          blau = 0;
          break;
      }

      values = rot + ' ' + gruen + ' ' + blau; // console.log(values);

      return values;
    },
    getXSeitenbauteilOben: function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var hDiag = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y');
      var hGurt = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y');
      ;
      var alfa = parseFloat(x3d.get('vWinkel'));
      var beta = parseFloat(x3d.get('winkelEndAbschnittDiagonaleGRAD'));
      var ueDiagVert = parseFloat(x3d.get('ueberstandDiagonaleVertikal'));
      var value = 0;

      if (alfa !== 0 && alfa !== 90) {
        var exzentrizitaet = 0;

        if (x3d.get('ausmittigerstabanschluss') === true) {
          exzentrizitaet = x3d.get('exzentrizitaetDiagonale');
        } else {
          exzentrizitaet = Number(hDiag / 2 / Math.sin(alfa * Math.PI / 180)) + Number(hGurt / 2 / Math.tan(alfa * Math.PI / 180));

          if (Number(x3d.get('winkelEndAbschnittGRAD')) === 1 && Number(ueDiagVert) > hDiag * Math.cos(alfa * Math.PI / 180)) {
            exzentrizitaet = exzentrizitaet + (ueDiagVert - hDiag * Math.cos(alfa * Math.PI / 180)) / Math.tan(alfa * Math.PI / 180);
          } else if (Number(x3d.get('winkelEndAbschnittGRAD')) === 2) {
            exzentrizitaet = exzentrizitaet + ueDiagVert / Math.tan(alfa * Math.PI / 180);
          }
        }

        value = (exzentrizitaet / Math.cos(alfa * Math.PI / 180) - hDiag / 2 * Math.tan(alfa * Math.PI / 180)) / 10;
      } else if (alfa === 90) {
        value = hGurt / 20;
      } else {
        value = Math.abs(x3d.get('xSeitenbauteil') / 10) + Math.sin(alfa * Math.PI / 180) * ueDiagVert / 10;
      }

      if (value < -(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')))) {
        var xOben = Math.abs(value) - Math.abs(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')));
        var yDeltaOben = Math.abs(xOben) / Math.tan(alfa * Math.PI / 180);
        this.set('yDeltaOben', yDeltaOben);
        value = -(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')));
      } else {
        this.set('yDeltaOben', 0);
      }

      return value;
    },
    getXSeitenbauteilUnten: function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var hDiag = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y');
      var hGurt = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y');
      var alfa = parseFloat(x3d.get('vWinkel'));
      var beta = parseFloat(x3d.get('winkelEndAbschnittDiagonaleGRAD'));
      var ueDiagVert = parseFloat(x3d.get('ueberstandDiagonaleVertikal'));
      var exzentrizitaet = x3d.get('exzentrizitaetDiagonale');
      var v1 = 0,
          v2 = 0,
          l = 0,
          value = 0;
      v1 = hDiag / 2 / Math.sin(alfa * Math.PI / 180) + hGurt / 2 / Math.tan(alfa * Math.PI / 180) - exzentrizitaet;
      v2 = hDiag / Math.sin(alfa * Math.PI / 180);
      l = v2 - v1;

      if (alfa !== 0 && alfa !== 90) {
        value = hGurt / 2 / Math.sin(alfa * Math.PI / 180) - (v2 - hDiag / 2 / Math.sin(alfa * Math.PI / 180)) * Math.cos(alfa * Math.PI / 180);

        if (x3d.get('ausmittigerstabanschluss') === false) {
          if (Number(x3d.get('winkelEndAbschnittGRAD')) === 1 && Number(ueDiagVert) >= Number((hDiag * Math.cos(alfa * Math.PI / 180)).toFixed(1))) {
            value = value + hDiag / Math.tan(alfa * Math.PI / 180) + (ueDiagVert - hDiag * Math.cos(alfa * Math.PI / 180)) / Math.sin(alfa * Math.PI / 180);
          } else if (Number(x3d.get('winkelEndAbschnittGRAD')) === 2) {
            value = value + ueDiagVert / Math.sin(alfa * Math.PI / 180);
          }
        } else if (x3d.get('ausmittigerstabanschluss') === true) {
          if (Number(x3d.get('winkelEndAbschnittGRAD')) === 1) {
            // value = value + (l-ueDiagVert/Math.tan(alfa * Math.PI / 180)) * Math.cos(alfa * Math.PI / 180) + ueDiagVert/Math.sin(alfa * Math.PI / 180);
            value = value + ueDiagVert / Math.sin(alfa * Math.PI / 180);
          } else if (Number(x3d.get('winkelEndAbschnittGRAD')) === 2) {
            value = value + ueDiagVert / Math.sin(alfa * Math.PI / 180);
          }

          if (value >= Number(((hDiag / 2 + exzentrizitaet / Math.sin(alfa * Math.PI / 180)) * Math.tan(alfa * Math.PI / 180)).toFixed(1))) {
            value = (hDiag / 2 + exzentrizitaet / Math.sin(alfa * Math.PI / 180)) * Math.tan(alfa * Math.PI / 180);
          }
        }

        value = value / 10;
      } else if (alfa === 90) {
        value = hGurt / 20;
      } else {
        value = Math.abs(x3d.get('xSeitenbauteil') / 10) + Math.sin(alfa * Math.PI / 180) * ueDiagVert / 10;
      }

      if (value < -(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')))) {
        var xUnten = Math.abs(value) - Math.abs(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')));
        var yDeltaUnten = Math.abs(xUnten) * Math.tan(alfa * Math.PI / 180);
        this.set('yDeltaUnten', yDeltaUnten);
      } else {
        this.set('yDeltaUnten', 0);
      }

      return value;
    },
    getXDeltaSeitenbauteilUnten: function (value) {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);

      if (value < -(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')))) {
        value = -(Math.abs(x3d.get('xMittelbauteil') / 10) + Number(this.get('ueberstand')));
      }

      return value;
    },
    getXExzentrizitaet: function (achse) {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var alfa = parseFloat(x3d.get('vWinkel'));
      var beta = parseFloat(x3d.get('winkelEndAbschnittDiagonaleGRAD'));
      var ueDiagVert = parseFloat(x3d.get('ueberstandDiagonaleVertikal'));
      var hDiag = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y');
      var hGurt = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y');
      ;
      var exzentrizitaet = Number(x3d.get('exzentrizitaetDiagonale'));
      var v1 = 0,
          v2 = 0,
          l = 0,
          value = 0;
      v1 = hDiag / 2 / Math.sin(alfa * Math.PI / 180) + hGurt / 2 / Math.tan(alfa * Math.PI / 180) - exzentrizitaet;
      v2 = hDiag / Math.sin(alfa * Math.PI / 180);
      l = v2 - v1;

      if (l - ueDiagVert / Math.tan(alfa * Math.PI / 180) >= 0) {
        if (achse === 'x') {
          if (Number(x3d.get('winkelEndAbschnittGRAD')) === 1) {
            value = 0;
          } else if (Number(x3d.get('winkelEndAbschnittGRAD')) === 2) {
            value = (l - ueDiagVert / Math.tan(alfa * Math.PI / 180)) * Math.cos(alfa * Math.PI / 180);
          }
        } else if (achse === 'y') {
          if (Number(x3d.get('winkelEndAbschnittGRAD')) === 1) {
            value = (l - ueDiagVert / Math.tan(alfa * Math.PI / 180)) / Math.sin(alfa * Math.PI / 180);
          } else if (Number(x3d.get('winkelEndAbschnittGRAD')) === 2) {
            value = (l - ueDiagVert / Math.tan(alfa * Math.PI / 180)) * Math.sin(alfa * Math.PI / 180);
          }
        }
      }

      value = value / 10;
      return value;
    },
    getPosition: function (x1, y1, x2, y2, xp, yp) {
      return (yp - y1) * (x2 - x1) - (y2 - y1) * (xp - x1);
    },
    indexedLineset: function (bauteil) {
      // console.log("###  x3dcontroller indexedLineset ###");
      return "<transform scaleorientation=\"0,0,0,0\" scale=\"1,1,1\" center=\"0 0 0\" bboxsize=\"-1,-1,-1\" bboxcenter=\"0 0 0\" render=\"true\" translation=\"0,0,0\" rotation=\"0,0,0,0\"><shape render=\"true\" bboxcenter=\"0,0,0\" bboxsize=\"-1,-1,-1\" ispickable=\"true\"><appearance sorttype=\"auto\" alphaclipthreshold=\"0.1\"><material emissivecolor=\"0 0 0\" ambientintensity=\"0.2\" diffusecolor=\"0.8,0.8,0.8\" shininess=\"0.2\" specularcolor=\"0,0,0\"></material></appearance><indexedlineset coordindex=\"1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1\" solid=\"true\" ccw=\"true\" usegeocache=\"true\" lit=\"true\" colorpervertex=\"true\" ><coordinate name=\"traeger\" typ=\"breite\" point=\"" + this.massketteKoordinaten(bauteil) + "\"></coordinate></indexedlineset></shape></transform>";
    },
    massketteKoordinaten: function (item) {
      // console.log();
      var bauteile = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('bauteile');
      var x = 0;
      var y = 0;
      var z = 0;
      var cords;
      var p1 = new Object();
      var p2 = new Object();
      var w90Grad = 90 * Math.PI / 180;
      var w180Grad = 180 * Math.PI / 180;
      var ueberstand = Number(this.get('ueberstand'));
      var verstaerkung = 0;
      p1.x = item.get('punkt').objectAt(1).get('x') / 10;
      p1.y = item.get('punkt').objectAt(1).get('y') / 10;
      p1.z = item.get('punkt').objectAt(1).get('z') / 10;
      p2.x = item.get('punkt').objectAt(0).get('x') / 10;
      p2.y = item.get('punkt').objectAt(0).get('y') / 10;
      p2.z = item.get('punkt').objectAt(0).get('z') / 10;
      var richtungsvektor1 = new Object();
      var richtungsvektor2 = new Object();
      var sichtEbene = "AnsichtXY";

      if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
        sichtEbene = "AnsichtXZ";
      } else if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
        sichtEbene = "AnsichtYZ";
      }

      sichtEbene = 'masskette' + sichtEbene + item.id.substring(0, item.id.length - 3);
      richtungsvektor1.x = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('x'));
      richtungsvektor1.y = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('y'));
      richtungsvektor1.z = Number(item.get('ansichtsvektoren').findBy('id', sichtEbene).get('z'));
      richtungsvektor2 = richtungsvektor1;
      var ebene = Number(item.get('masskette').objectAt(0).get('ebene'));
      cords = this.getVektorKoordinaten(p1, p2, richtungsvektor1, richtungsvektor2, ebene);
      return cords;
    },
    getVektorKoordinaten: function (punkt1, punkt2, r1, r2, ebene) {
      var dr = 0.2 * (ebene - 1);
      var b05 = 0.05;
      var b1 = 0.1;
      var b5 = 0.5;
      var b45 = b5 - b05;
      var b55 = b5 + b05;
      var b6 = 0.6;
      var verschiebung = b5 * (ebene - 1) + dr;
      var p0 = Number(punkt1.x) + verschiebung * Number(r1.x) + ' ' + (Number(punkt1.y) + verschiebung * Number(r1.y)) + ' ' + (Number(punkt1.z) + verschiebung * Number(r1.z));
      var p1 = Number(punkt2.x) + verschiebung * Number(r2.x) + ' ' + (Number(punkt2.y) + verschiebung * Number(r2.y)) + ' ' + (Number(punkt2.z) + verschiebung * Number(r2.z));
      var p2 = Number(punkt2.x) + (b6 + verschiebung) * Number(r2.x) + ' ' + (Number(punkt2.y) + (b6 + verschiebung) * Number(r2.y)) + ' ' + (Number(punkt2.z) + (b6 + verschiebung) * Number(r2.z));
      var p3 = Number(punkt1.x) + (b6 + verschiebung) * Number(r1.x) + ' ' + (Number(punkt1.y) + (b6 + verschiebung) * Number(r1.y)) + ' ' + (Number(punkt1.z) + (b6 + verschiebung) * Number(r1.z));
      var p4 = this.getXYZ(punkt1, punkt2, r1, r2, b5 + verschiebung, b1);
      var p5 = this.getXYZ(punkt2, punkt1, r2, r1, b5 + verschiebung, b1);
      var p6 = this.getXYZ(punkt2, punkt1, r2, r1, b45 + verschiebung, b05);
      var p8 = this.getXYZ(punkt1, punkt2, r1, r2, b45 + verschiebung, -b05);
      var p7 = this.getXYZ(punkt2, punkt1, r2, r1, b55 + verschiebung, -b05);
      var p9 = this.getXYZ(punkt1, punkt2, r1, r2, b55 + verschiebung, b05);
      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;
      return koordinaten;
    },
    getXYZ: function (punkt1, punkt2, r1, r2, richtungsAbstand, seitenAbstand) {
      var p1_2 = {
        x: Number(punkt1.x) + richtungsAbstand * Number(r1.x),
        y: Number(punkt1.y) + richtungsAbstand * Number(r1.y),
        z: Number(punkt1.z) + richtungsAbstand * Number(r1.z)
      };
      var p2_2 = {
        x: Number(punkt2.x) + richtungsAbstand * Number(r2.x),
        y: Number(punkt2.y) + richtungsAbstand * Number(r2.y),
        z: Number(punkt2.z) + richtungsAbstand * Number(r2.z)
      };
      var r3 = {
        x: p2_2.x - p1_2.x,
        y: p2_2.y - p1_2.y,
        z: p2_2.z - p1_2.z
      };
      var lr3 = this.vektorLaenge(r3.x, r3.y, r3.z);

      if (lr3 === 0) {
        console.log("!!!!! Bei " + this.get('name') + " Division durch 0 !!!!!");
        console.log('punkt1');
        console.log(punkt1);
        console.log('punkt2');
        console.log(punkt2);
        console.log('Länge von punkt2 zu punkt1');
        console.log(lr3);
        console.log(' ');
        lr3 = 1;
      }

      var r3Einheitsvektor = {
        x: r3.x / lr3,
        y: r3.y / lr3,
        z: r3.z / lr3
      };
      var x = p1_2.x + r3Einheitsvektor.x * (lr3 + seitenAbstand);
      var y = p1_2.y + r3Einheitsvektor.y * (lr3 + seitenAbstand);
      var z = p1_2.z + r3Einheitsvektor.z * (lr3 + seitenAbstand);
      return x + ' ' + y + ' ' + z;
    },
    vektorLaenge: function (x, y, z) {
      return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2));
    }
  });

  _exports.default = _default;
});