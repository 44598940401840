define("m12-2020/controllers/impressum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend((_obj = {
    countryCode: "DE",
    init: function () {
      this._super();

      console.log("inti impressum");
    },
    germany: Ember.computed('countryCode', function () {
      var self = this;

      if (self.get("countryCode") === "DE") {
        return true;
      } else {
        return false;
      }
    }),
    austria: Ember.computed('countryCode', function () {
      var self = this;

      if (self.get("countryCode") === "AT") {
        return true;
      } else {
        return false;
      }
    }),
    swiss: Ember.computed('countryCode', function () {
      var self = this;

      if (self.get("countryCode") === "CH") {
        return true;
      } else {
        return false;
      }
    }),

    contactproduction() {
      var part1 = "info";
      var part2 = Math.pow(2, 6);
      var part3 = String.fromCharCode(part2);
      var part4 = "swg-produktion.de";
      console.log("mai" + "lto" + ":" + part1 + part3 + part4);
      window.location.href = "mai" + "lto" + ":" + part1 + part3 + part4;
    },

    contactengineering() {
      var part1 = "support.it";
      var part2 = Math.pow(2, 6);
      var part3 = String.fromCharCode(part2);
      var part4 = "swg-engineering.de";
      window.location.href = "mai" + "lto" + ":" + part1 + part3 + part4;
    }

  }, (_applyDecoratedDescriptor(_obj, "contactproduction", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "contactproduction"), _obj), _applyDecoratedDescriptor(_obj, "contactengineering", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "contactengineering"), _obj)), _obj));

  _exports.default = _default;
});