define("m12-2020/controllers/lasteinwirkung", ["exports", "m12-2020/mixins/object-validator", "jquery"], function (_exports, _objectValidator, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Controller.extend(_objectValidator.default, (_obj = {
    debug: true,
    openFromFile: false,
    displayErrors: true,
    loading: false,
    bmlast: "",
    verbindungsmittelliste: "",
    nkl: 1,
    led: 1,
    gabellager: false,
    torsionsmoment: "0.0",
    intl: Ember.inject.service(),
    ttBmLast: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 0.01 - 9999.9 [kN]";
      },

      set(key, value) {
        return value;
      }

    }),
    ttTorsionsmoment: Ember.computed('intl.locale', {
      get(key) {
        return this.get('intl').t('wertebereich') + " 0.0 - 999.0 [Nm]";
      },

      set(key, value) {
        return value;
      }

    }),
    validations: {
      nkl: {
        // type: "",
        // tooltip: "ttNutzungsklasse",
        numericality: {
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 3,
          message: "*"
        }
      },
      bmlast: {
        numericality: {
          greaterThan: 0.01,
          lessThanOrEqualTo: 9999.9,
          message: "*"
        }
      },
      torsionsmoment: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 999.9,
          message: "*"
        }
      }
    },
    _data: [{
      'text': 'Company 1',
      'id': 'company-1',
      'state': {
        'opened': true
      },
      'a_attr': {
        'people': 100
      },
      'children': [{
        'text': 'Headoffice',
        'children': [{
          'text': 'Branch 1'
        }, {
          'text': 'Branch 2'
        }]
      }]
    }, {
      'text': 'Company 2',
      'children': [{
        'text': 'Headoffice'
      }]
    }],
    _xTreeData: [{
      id: 0,
      name: 'Root',
      isExpanded: true,
      isSelected: false,
      isVisible: true,
      children: [{
        id: 1,
        name: 'First Child',
        isExpanded: true,
        isSelected: false,
        isVisible: true,
        children: []
      }, {
        id: 2,
        name: 'Second Child',
        isExpanded: true,
        isSelected: false,
        isVisible: true,
        children: [{
          id: 3,
          name: 'First Grand Child',
          isExpanded: true,
          isSelected: true,
          isVisible: true,
          children: []
        }]
      }]
    }],
    nklarray: [],
    ledarray: [],
    timberNA: "DE",
    applicationController: Ember.inject.controller('application'),
    traegerController: Ember.inject.controller('traeger'),
    lasteinwirkungController: Ember.inject.controller('lasteinwirkung'),
    superController: Ember.inject.controller('supercontroller'),
    downloadcenterController: Ember.inject.controller('downloadcenter'),
    verbindungsmittelController: Ember.inject.controller('verbindungsmittel'),
    init: function () {
      this._super();

      console.log("inti lasteinwirkung");
    },
    initialisierung: function () {
      this.setSelectFieldsContent();
      this.send('validation', -1, {
        target: {
          name: "foobar"
        }
      });
    },
    setValues: function (values) {
      if (self.debug) {
        console.log("values in lasteinwirkung: " + values);
      }

      this.set('openFromFile', true);
      this.set('nkl', parseInt(values.NKL_ID));
      this.set('led', parseInt(values.KLED_ID));
      this.set('bmlast', values.V_d);
      this.send('validation');
      this.set('openFromFile', false);
    },
    setSelectFieldsContent: function () {
      var self = this;
      self.setnklarray();
      self.setledarray();
      self.watchComboboxes();
    },
    setnklarray: function () {
      var self = this;
      var indices = [1, 2, 3];
      var maxNKLM1 = materialien.liste.findBy('IndexNr', self.get('traegerController').get('ntfkl')).MaxNKL;
      var maxValues = [maxNKLM1];
      maxValues = maxValues.sort(function (a, b) {
        return a - b;
      });
      self.set('validations.nkl.numericality.lessThanOrEqualTo', maxValues[0]);
      self.set('nklarray', self.getSelectFieldContent('nklassen', indices, Number(maxValues[0]), self.get('nkl')));
    },
    setledarray: function () {
      var self = this;
      var indices = [];

      if (self.get('timberNA') === "DE" || self.get('timberNA') === "AT") {
        indices = [1, 2, 3, 4, 45, 5];
      } else {
        if (self.get('led') === 45) {
          self.set('led', 1);
        }

        indices = [1, 2, 3, 4, 5];
      }

      self.set('ledarray', self.getSelectFieldContent('ledarray', indices, 9999, self.get('led')));
    },
    getSelectFieldContent: function (db, indexes, maxValue, selectedValue) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);

      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            var disabled = false;

            if (db === 'nklassen' && Number(dbContent[k].id) > maxValue) {
              disabled = true;
            }

            tmp.push({
              id: dbContent[k].id,
              name: this.get('intl').t(dbContent[k].name),
              disabled: disabled,
              selected: selectedValue === dbContent[k].id ? true : false
            });
          }
        }
      }

      return tmp;
    },
    getDBContent: function (db) {
      var dbContent = [];

      switch (db) {
        case 'nklassen':
          dbContent = nutzungsklassenDB.nutzungsklassen_de;
          break;

        case 'ledarray':
          dbContent = ledDB.led_de;
          break;
      }

      return dbContent;
    },
    getValueFromSelectField: function (contentArray, index) {
      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }

      return tmp;
    },
    watchNumericalInputs: Ember.observer('bmlast', 'torsionsmoment', function () {
      if (!this.openFromFile) {
        if (this.get('bmlast').toString().indexOf(",") !== -1) {
          this.set('bmlast', this.get('bmlast').toString().replace(",", "."));
        }

        if (typeof this.get('torsionsmoment') !== 'undefined' && this.get('torsionsmoment').toString().indexOf(",") !== -1) {
          this.set('torsionsmoment', this.get('torsionsmoment').toString().replace(",", "."));
        }
      }
    }),
    watchComboboxes: Ember.observer('led', 'nkl', function () {
      var self = this;
      self.get('superController').resetVerbindungsmittel();
      var lasteinwirkung = this.get('applicationController').get('model').lasteinwirkung.objectAt(0);
      lasteinwirkung.set('NKL_ID', this.get('nkl'));
      var nklText = this.nklarray[this.get('nkl') - 1].name.toString();
      lasteinwirkung.set('NKL_Text', nklText);
      lasteinwirkung.set('KLED_ID', this.get('led'));
      var ledText = this.ledarray.findBy('id', this.get('led')).name.toString();
      lasteinwirkung.set('LED_Text', ledText);
    }),
    setX3D: function (value, event) {
      var self = this;
      var x3ddefault = self.get('applicationController').get('model').x3ddefault.objectAt(0);
      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case 'bmlast':
          if (self.get('errors').get("bmlast") === undefined) {
            x3ddefault.set('bemessungslast', value);
          }

          break;

        case 'torsionsmoment':
          if (self.get('errors').get("torsionsmoment") === undefined) {
            x3ddefault.set('torsionsmoment', value);
            x3ddefault.set('torsionsmomentEingetragen', true);
          } else {
            x3ddefault.set('torsionsmomentEingetragen', false);
          }

          break;
      }

      x3ddefault.set('transformHelper', !x3ddefault.get('transformHelper'));
    },

    setNkl(value) {
      this.set('nkl', Number(value.target.value));
    },

    setLed(value) {
      this.set('led', Number(value.target.value));
    },

    proceedInput: function () {
      var self = this; // var ledText = self.ledarray.findBy('id', this.get('led')).name.toString();
      // var nklText = self.nklarray[self.get('nkl') - 1].name.toString();
      //

      if (self.validate() === true) {
        var ledText = this.ledarray.findBy('id', this.get('led')).name.toString();
        var nklText = self.nklarray[self.get('nkl') - 1].name.toString();
        var lasteinwirkung = self.store.createRecord('lasteinwirkung', {
          KLED_ID: self.get('led'),
          LED_Text: ledText,
          NKL_ID: self.get('nkl'),
          NKL_Text: nklText,
          V_d: self.get('bmlast').replace(",", "."),
          Torsionsmoment: self.get('torsionsmoment')
        });
        var applicationdata = self.get('applicationController').get('model').application.objectAt(0);
        applicationdata.set('treeLoaded', false);
        applicationdata.set('initialized', false);
        applicationdata.set('pdfErstellt', false);
        var downloadcenter = self.get('downloadcenterController');
        downloadcenter.set('pdfErstellt', false);
        self.get('applicationController').set('verbindungsmittelInvalid', true);
        self.set('displayErrors', false);
        self.get('applicationController').set('lasteinwirkungInvalid', false);
        var applicationdataJSON = JSON.stringify(applicationdata);
        var JSONdata = JSON.stringify(self.get('superController').uebergabedatenErzeugen());
        console.log('JSONdata: ' + JSONdata);
        self.get('verbindungsmittelController').set('loading', true);
        self.set('loading', true);
        self.transitionToRoute('/verbindungsmittel');
        var initialized = applicationdata.get('initialized');
        var server = self.get('applicationController').get('server');
        var pfad = self.get('applicationController').get('pfad');

        if (!initialized) {
          console.log('is not Initialized');

          _jquery.default.ajax({
            type: "POST",
            url: server + "initialisiereBerechnung/",
            data: {
              kennung: applicationdataJSON,
              paras: JSONdata,
              modul: "m12",
              pfad: pfad
            }
          }).done(function (data, statusText, xhr) {
            var cid = data;
            applicationdata.set('Calculation_ID', cid);
            applicationdata.set('initialized', true);
            self.get('superController').logaufruf("initialisiereBerechnung", xhr.status);

            _jquery.default.ajax({
              type: "POST",
              url: server + "erzeugeSchraubenliste/",
              data: {
                cid: cid
              }
            }).done(function (data, statusText, xhr) {
              console.log("data;", data);
              console.log("data;", statusText);
              self.get('superController').logaufruf("erzeugeSchraubenliste", xhr.status);

              if (Number(JSON.parse(data).Item1) === 0) {
                var liste = JSON.parse(JSON.parse(data).Item2);
                self.set('verbindungsmittelliste', liste);
                self.get('verbindungsmittelController').set('sListe', liste);
                self.get('verbindungsmittelController').setParameterValues(liste, null);
                self.get('verbindungsmittelController').set('sListeTrigger', !self.get('verbindungsmittelController').get('sListeTrigger'));
                self.get('verbindungsmittelController').set('keineSchraubenliste', false);
              } else if (Number(JSON.parse(data).Item1) === 1) {
                self.get('applicationController').set('verbindungsmittelInvalid', false);
                self.get('applicationController').get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', true);
                self.get('verbindungsmittelController').set('infoText', JSON.parse(data).Item3);
                self.get('verbindungsmittelController').set('keineSchraubenliste', true);
              }

              self.get('verbindungsmittelController').set('calcid', cid);
              self.get('verbindungsmittelController').set('loading', false);
              applicationdata.set('treeLoaded', true);
              self.set('loading', false);
            });
          });
        } else {
          console.log('is Initialized');
          var cid = applicationdata.get('Calculation_ID');

          _jquery.default.ajax({
            type: "POST",
            url: server + "erzeugeSchraubenliste/",
            data: {
              cid: cid
            }
          }).done(function (data, statusText, xhr) {
            console.log('DATA ' + data);
            self.get('superController').logaufruf("erzeugeSchraubenliste", xhr.status);

            if (Number(JSON.parse(data).Item1) === 0) {
              var liste = JSON.parse(JSON.parse(data).Item2);
              self.verbindungsmittelliste = liste; // var core = treetools.buildTree(liste, true);
              // self.get('verbindungsmittelController').set('schraubenliste', core);

              self.get('verbindungsmittelController').set('keineSchraubenliste', false);
            } else if (Number(JSON.parse(data).Item1) === 1) {
              self.get('applicationController').set('verbindungsmittelInvalid', false);
              self.get('applicationController').get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', true);
              self.get('verbindungsmittelController').set('infoText', JSON.parse(data).Item3);
              self.get('verbindungsmittelController').set('keineSchraubenliste', true);
            }

            self.get('verbindungsmittelController').set('loading', false);
            self.get('verbindungsmittelController').set('calcid', cid);
            applicationdata.set('treeLoaded', true);
            self.set('loading', false);
          });
        }
      } else {
        self.set('displayErrors', true);
        self.get('applicationController').set('lasteinwirkungInvalid', true);
      }
    },

    validation(value, event) {
      var self = this;

      if (!self.openFromFile) {
        this.setX3D(value, event);
      }

      if (self.validate() === true) {
        self.set('displayErrors', false);
        self.get('applicationController').set('lasteinwirkungInvalid', false);
        var applicationdata = self.get('applicationController').get('model').application.objectAt(0);
        applicationdata.set('treeLoaded', false);
        applicationdata.set('initialized', false);
        self.get('applicationController').set('verbindungsmittelInvalid', true);
        self.get('applicationController').get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', false);
        var lasteinwirkung = self.get('applicationController').get('model').lasteinwirkung.objectAt(0);
        lasteinwirkung.set('V_d', parseFloat(self.get('bmlast')).toFixed(1));
        lasteinwirkung.set('Torsionsmoment', parseFloat(self.get('torsionsmoment')).toFixed(1));
      } else {
        self.set('displayErrors', true);
        self.get('applicationController').set('lasteinwirkungInvalid', true);
      }
    },

    bmlastIsSelected() {
      var bmlastaktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('bemessungslastAktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('bemessungslastAktiv', !bmlastaktiv);

      if (this.get('bmlast') !== "") {
        var bmlastwork = parseFloat(this.get('bmlast').toString().replace(",", "."));
        var bmlastrounded = bmlastwork.toFixed(1);
        this.set('bmlast', bmlastrounded);
        this.send('validation', bmlastrounded, {
          target: {
            name: "bmlast"
          }
        });
      }

      if (bmlastaktiv === false) {
        document.getElementsByName('bmlast')[0].setSelectionRange(0, this.get('bmlast').length);
      }
    },

    torsionsmomentIsSelected() {
      var torsionsmomentAktiv = this.get('applicationController').get('model').x3ddefault.objectAt(0).get('torsionsmomentAktiv');
      this.get('applicationController').get('model').x3ddefault.objectAt(0).set('torsionsmomentAktiv', !torsionsmomentAktiv);

      if (typeof this.get('torsionsmoment') !== 'undefined') {
        if (this.get('torsionsmoment') !== "") {
          var work = parseFloat(this.get('torsionsmoment').toString().replace(",", "."));
          var rounded = work.toFixed(1);
          this.set('torsionsmoment', rounded);
          this.send('validation', rounded, {
            target: {
              name: "torsionsmoment"
            }
          });
        }

        if (torsionsmomentAktiv === false) {
          document.getElementsByName('torsionsmoment')[0].setSelectionRange(0, this.get('torsionsmoment').length);
        }
      }
    }

  }, (_applyDecoratedDescriptor(_obj, "setNkl", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "setNkl"), _obj), _applyDecoratedDescriptor(_obj, "setLed", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "setLed"), _obj), _applyDecoratedDescriptor(_obj, "validation", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "validation"), _obj), _applyDecoratedDescriptor(_obj, "bmlastIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "bmlastIsSelected"), _obj), _applyDecoratedDescriptor(_obj, "torsionsmomentIsSelected", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "torsionsmomentIsSelected"), _obj)), _obj));

  _exports.default = _default;
});