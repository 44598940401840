define("m12-2020/components/screw-tablerow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var Multiselectbox = Ember.Component.extend((_obj = {
    tagName: 'tr',
    layoutName: 'screw-tablerow',
    attributeBindings: [],
    hideTip: true,
    // click(item){
    //   console.log("click click");
    //   console.log(item);
    //   // console.log(this.get('item').artNr+" "+this.get('item').valid+" "+this.get('item').produktInfo+" "+this.get('item').cad);
    //
    //   // this.controllerSetSelectedScrew(this.item.artNr, this.item.valid, this.item.produktInfo, this.item.cad);
    // },
    itemID: Ember.computed('item', function () {
      return this.item.ArtNr.toString().replace(/ /g, "");
    }),

    setTipVisible(e, item) {
      if (this.item.ToolTipText !== "") {
        const xPos = e.element.offsetLeft + e.element.offsetWidth + 73;
        const yPos = e.element.getBoundingClientRect().top + window.scrollY;
        $("#tooltipfenster").append(this.item.ToolTipText);
        $("#tooltipfenster").toggleClass("displayNone");
        $("#tooltipfenster").toggleClass("VM_OkayInfo" + this.item.VM_Okay);
        $("#tooltipfenster").offset({
          left: xPos,
          top: yPos - $("#tooltipfenster").height() / 2
        });
      }
    },

    setTipHidden(e, item) {
      if (this.item.ToolTipText !== "") {
        $("#tooltipfenster").text("");
        $("#tooltipfenster").toggleClass("displayNone");
        $("#tooltipfenster").toggleClass("VM_OkayInfo" + this.item.VM_Okay);
      }
    },

    setSelectedScrew(_artNr, _valid, _produktInfo, _cad) {
      this.cSetSelectedScrew(_artNr, _valid, _produktInfo, _cad);
    }

  }, (_applyDecoratedDescriptor(_obj, "setTipVisible", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "setTipVisible"), _obj), _applyDecoratedDescriptor(_obj, "setTipHidden", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "setTipHidden"), _obj), _applyDecoratedDescriptor(_obj, "setSelectedScrew", [Ember._action], Object.getOwnPropertyDescriptor(_obj, "setSelectedScrew"), _obj)), _obj));
  var _default = Multiselectbox;
  _exports.default = _default;
});